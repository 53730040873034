import appConfig from 'configs/app.config'  
const { apiPrefixServer } = appConfig

//funcion para iniciar sesion
export function getLogin(companyid,username,password) {
    const url = `${apiPrefixServer}/get-login/${companyid}/${username}/${password}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "applicaction/json"
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
