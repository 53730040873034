const appConfig = {
    apiPrefix: '/api',
    apiReportsPrefix: '/api',
    apiPrefixServer: 'https://server.importadoramachuca.com/api',
    //apiPrefixServer: 'http://localhost:3050/api',  
    apiReportsServer: 'https://reports.importadoramachuca.com/api', //https://localhost:44351/api, 
    authenticatedEntryPath: '/pages/welcome' ,
    AZURE_STORAGE_CONNECTION_STRING: "DefaultEndpointsProtocol=https;AccountName=ucontrolblob;AccountKey=+rQxrygR38wVj7Xq+f6hNKw93h9QMIYt1auEWYMhEphnvt4DqwEIiJAO8PkntJn0N1onkrmOvkhxCT5tr02Yxw==;EndpointSuffix=core.windows.net",
    AZURE_containername:"importadoramachuca",
    storageAccountName: "ucontrolblob",
    sasToken: "sp=racwdl&st=2022-11-25T14:10:32Z&se=2030-11-25T22:10:32Z&spr=https&sv=2021-06-08&sr=c&sig=Gu7v8ihX8orp5mxPOsA0cwtq8NDtkC6Oje%2BhlpYZhQU%3D",
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true
}

export default appConfig